/* Global Content Container */
.resume-container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 1rem; /* Add padding for better spacing */
    display: flex;
    flex-direction: column;
    margin-top: 7rem;
}

/* Section Layout */
.section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-bottom: 5rem; /* Spacing between sections */
}

/* Section Header */
.section-header {
    max-width: 1600px;
    text-align: left;
    margin-bottom: 2rem;
}

.section-header h1 {
    font-size: 3rem;
    color: var(--title-color);
    font-family: var(--title-font-family);
    font-weight: 700;
    line-height: var(--line-height-base);
}

/* Content Grid for Images and Descriptions */
.content-grid {
    display: flex;
    flex-direction: row; /* Align items in a row */
    gap: 2rem; /* Space between the image wrapper and description */
    justify-content: center; /* Center align the content grid */
    align-items: flex-start; /* Align items to the top */
    width: 100%; /* Use the full width of the section */
    max-width: 1600px; /* Optional: constrain the max width */
    margin: 0 auto; /* Center the grid on the page */
}

/* Image Wrapper - 50% width */
.content-grid .image-wrapper {
    flex: 1; /* Equal width to the description */
    display: flex;
    flex-direction: row; /* Images laid out side by side */
    flex-wrap: wrap; /* Wrap images if needed */
    gap: 1rem; /* Add spacing between images */
    justify-content: left; /* Center align images within the wrapper */
}

.image-wrapper img {
    width: 100%;
    max-width: 200px;
    border-radius: 0.5rem;
}

/* Description Wrapper - 50% width */
.content-grid .description-wrapper {
    flex: 1; /* Equal width to the image wrapper */
    max-width: 800px; /* Optional: constrain the maximum width */
    text-align: left;
}

.description-wrapper p {
    color: var(--reg-text-color);
    font-family: var(--font-family-light);
    font-size: 1rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
    margin-top: 0;
}

.description-wrapper p strong {
    color: var(--highlight-color);
    font-size: 1.5rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
}

.description-wrapper p em{
    color: var(--title-color);
    font-size: 1.3rem;
    line-height: 1.5rem;
    margin-bottom: 1rem;
}

