#intro {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    background-color:#101720;
    color: white;
    margin-bottom: 3rem;
}

.overlay {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.overlay .sections {
    margin-top: 7rem;
    width: 100%;
    max-width: 1600px
}

.main-page-intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.main-page-intro h1 {
    font-family: var(--title-font-family);
    letter-spacing: .3rem;
    font-size: 56px;
    color: var(--title-color);
    font-weight: 600;
    margin-bottom: 2rem;
    text-transform: capitalize;
}

.main-page-intro h3 {
    font-family: "Titillium Web", sans-serif;
    width: 1210px;
    letter-spacing: 0.15rem;
    font-size: 30px;
    color: var(--title-color);
    font-weight: 500;
    margin-bottom: 5px;
    padding-bottom: 5px;
    text-transform: capitalize;
    text-align: center;
    position: relative;
}

.main-page-intro h4 {
    font-family: "Titillium Web", sans-serif;
    width: 1210px;
    letter-spacing: 0.1rem;
    font-size: 30px;
    color: var(--title-color);
    font-weight: 600;
    margin-top: 10px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    text-transform: capitalize;
    text-align: left;
    position: relative;
}

.main-page-intro h5 {
    font-family: "Titillium Web", sans-serif;
    width: 1210px;
    letter-spacing: 0.3rem;
    font-size: 20px;
    color: var(--title-color);
    font-weight: 500;
    margin-bottom: 5px;
    padding-bottom: 5px;
    text-transform: capitalize;
    text-align: left;
    position: relative;
}

.main-page-intro h6 {
    font-family: "Titillium Web", sans-serif;
    font-size: 20px;
    color: var(--title-color);
}


.main-page-intro span {
    font-family: 'Titillium Web', sans-serif;
    width: 1210px;
    font-size: 20px;
    color: var(--reg-text-color);
    line-height: 26px;
    margin-bottom: 5px;
    padding-bottom: 5px;
}

.sliders-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
    color: #bdc3c7;
    font-size: 20px;
  }
  
  .slider-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: 300px;
  }

  .slider-item h3{
    font-family: "Titillium Web", sans-serif;
    width: 300px;
    letter-spacing: 0.15rem;
    font-size: 20px;
    color: white;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    position: relative;
  }

  .slider-item h4{
    font-family: "Titillium Web", sans-serif;
    width: 500px;
    letter-spacing: 0.15rem;
    font-size: 20px;
    color: white;
    font-weight: 500;
    text-transform: capitalize;
    text-align: center;
    position: relative;
  }

  .slider-item span{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 300px;
  }