/* About Page Layout */
#about-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 7rem;
}

.page-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 2rem; /* Add spacing between sections */
    width: 100%;
    max-width: 1600px
}

/* Introduction Section */
#aboutme-intro {
    text-align: center;
    margin-bottom: 3rem;
}

.introduction h1 {
    font-family: var(--title-font-family);
    font-weight: 400;
    font-size: 2.5rem;
    color: var(--title-color);
    letter-spacing: 0.1rem;
}

.introduction h1 span {
    display: block;
}

/* About Me Section */
#aboutme-main {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 2rem;
    margin-bottom: 3rem;
}

.image-container img {
    width: 100%;
    max-width: 450px;
    border-radius: 0.5rem;
}

.about-text {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    padding-left: 2rem;
}

.font-about-me {
    font-family: var(--font-family-light);
    color: var(--reg-text-color);
    font-size: var(--font-size-large);
    line-height: var(--line-height-large);
    margin: 0;
}

.highlight {
    color: var(--highlight-color);
    font-weight: 500;
}

/* What, Why, How Section */
#aboutme-content {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 3rem;
}

.section-content .row {
    display: flex;
    flex-direction: column;
    color: var(--reg-text-color);
    margin-bottom: 1rem;
}

.question {
    font-weight: 600;
    font-size: var(--font-size-large);
    color: var(--highlight-color);
}

/* Responsive Design */
@media (max-width: 768px) {
    #aboutme-main {
        flex-direction: column;
        align-items: center;
    }

    .image-container img {
        max-width: 100%;
    }
}